import { GroupType } from '@innedit/innedit-type';
import { navigate, PageProps } from 'gatsby';
import { GroupData } from 'packages/innedit';
import React, { FC } from 'react';

import HOCGroup from '~/components/Group/HOC';
import Group from '~/components/List/Item/Group';
import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import ListBody from '~/containers/Espace/List/Body';
import params from '~/params/group.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceContactsGroupeUpdate: FC<
  PageProps & EspaceProps & UserProps
> = ({ espace, location, params: { espaceId, id }, user }) => (
  <TemplateEspace
    collectionName="groups"
    docId={id}
    espace={espace}
    user={user}
  >
    <CMSView>
      <Form
        docId={id}
        itemPathnamePrefix={`/espaces/${espaceId}/contacts/groups/`}
        model={
          new GroupData({
            espaceId,
            params,
          })
        }
        name="item"
        type="update"
      >
        <HOCGroup
          addOnClick={() =>
            navigate(`/espaces/${espaceId}/contacts/groups/create?parent=${id}`)
          }
          bodyProps={{
            className: 'p-0',
          }}
          display="content"
          title="Sous-tâches"
        >
          <ListBody<GroupType, GroupData>
            allowSorting
            itemList={Group}
            itemPathnamePrefix={`/espaces/${espaceId}/contacts/groups/`}
            model={
              new GroupData({
                espaceId,
                params,
                orderDirection: 'asc',
                orderField: 'label',
                parentId: id,
                wheres: {
                  parent: id,
                },
              })
            }
            pathname={location.pathname}
            search={location.search}
            title="Sous-groupes"
            user={user}
          />
        </HOCGroup>
      </Form>
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageEspaceContactsGroupeUpdate);
